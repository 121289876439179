p {
  margin: 0;
}

.App {
  text-align: center;
  font-family: 'Droid Sans',Helvetica,Arial,Lucida,sans-serif;
}

h1, h2, h3 {
  margin: 0;
}

