.filter-toggle-min {
  background: #54B846;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
}

.filter-min-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 9px 15px 9px 15px;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
}

.filter-min-item-child {
  padding: 9px 15px 9px 30px;
}

.filter-min-item:first-child {
  border-radius: 5px 5px 0 0;
}

.filter-min-item:last-child {
  border: none;
}

.filter-min-container {
  border: 1px solid #908c8c;
  border-radius: 5px;
  margin-bottom: 10px;
}

.filter-min-parent {
  display: flex;
  justify-content: space-between;
}

.filter-min-parent img{
  display: flex;
  justify-content: space-between;
  padding: 0 0 5px 0;
}