.leadership-container {
  display: flex;
  flex-direction: column;
  padding: 69px 27px 0 27px;
}

.leadership-header-image {
  background: url('../images/leadershipHeader.png');
  width: 100%;
  height: 230px;
  position: absolute;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.leadership-header-text {
  text-align: center;
  padding: 20px 0 45px;
  color: #54b846;
}

.leadership-ceo-container {
  padding: 0 0 40px 0;
}

.leadership-content-container {
  padding: 260px 0 80px;
}

.leadership-team-container {
  display: grid;
  grid-template-columns: repeat(1,minmax(280px, 1fr));
  grid-gap: 40px 15px;
}

.leadership-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.leadership-card-img-container {
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.leadership-card-img {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  object-fit: cover;
  object-position: center;
}

.leadership-card-text-container {
  text-align: center;
  padding-top: 10px;
}

.leadership-modal-container {
  height: 100%;
  width: 100%;
  position: fixed;
  background: #0000009c;
  z-index: 99999;
}

.leadership-modal {
  position: fixed;
  width: 95%;
  max-width: 430px;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  color: #000;
  text-align: center;
}

.leadership-modal h2 {
  color: #54b846;
  font-weight: 600;
}

.leadership-modal h3 {
  font-weight: 500;
}

.leadership-modal p {
  font-weight: 100;
  padding: 13px 30px 0;
  font-family: 'AirbnbBook';
  white-space: pre-wrap;
}

.leadership-close-modal {
  width: 20px;
  height: 82px;
  background: url('../images/close-button.png');
  background-repeat: no-repeat;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .leadership-container {
    padding: 118px 27px 0 27px;
  }

  .leadership-team-container {
    grid-template-columns: repeat(2,minmax(280px, 1fr));
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .leadership-team-container {
    grid-template-columns: repeat(3,minmax(280px, 1fr));
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .leadership-container {
    max-width: 1200px;
    margin: 0 auto;
  }
}