.distributors-container {
  display: flex;
  flex-direction: column;
  padding: 69px 27px 0 27px;
}

.distributors-header-image {
  background: url('../images/leadershipHeader.png');
  width: 100%;
  height: 146px;
  position: absolute;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.distributors-content-container {
  padding: 176px 0 25px;
}

.distributors-header-text {
  padding: 15px 0 30px;
}

.distributors-header-text h1 {
  color: #54b846;
}

.distributors-header-text p {
  font-weight: 100;
}

.distributors-data-container {
  color: #212121;
  border-top: 1px solid gray;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
}

.distributors-parent {
  padding: 5px 10px;
  border-bottom: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

/* .distributors-parent:last-of-type {
    border-bottom: none;
} */

.distributors-province {
  padding: 5px 10px 5px 30px;
  border-bottom: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-family: 'AirbnbBook';
}

.distributors-child {
  padding: 5px 10px 5px 50px;
  border-bottom: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-family: 'AirbnbBook';
}

.distributors-child p {
  white-space: pre-wrap;
}

.distributors-empty-container {
  /* max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease; */
  padding: 5px 10px;
  border-bottom: 1px solid gray;
  display: flex;
  flex-direction: column;
}

.distributors-empty {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.distributors-empty-text {
  padding: 10px 0;
}

.distributors-empty-text h3 {
  padding-bottom: 8px;
}

.distributors-empty-btn {
  padding: 5px 10px;
  background: #54b846;
  margin-top: 10px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.distributors-empty-btn a {
  color: #fff;
  text-decoration: none;
}

.distributors-bottom-container {
  background: #54b846;
  color: #fff;
}

.distributors-content {
  padding: 30px 27px;
  text-align: center;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .distributors-container {
    padding: 118px 27px 0 27px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .distributors-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .distributors-content {
    max-width: 1200px;
    margin: 0 auto;
  }
}
