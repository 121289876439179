.accessibility-container {
  display: flex;
  flex-direction: column;
  padding: 55px 27px 0 27px;
  position: relative;
}

.accessibility-content {
  padding-top: 130px;
}

.accessibility-content-container-title {
  margin-bottom: 12px;
}

.accessibility-content-container-subtitle {
  margin-top: 0px;
}

.accessibility-paragraph {
  margin-bottom: 24px;
}

.accessibility-subheading {
  margin-bottom: 12px;
}

.underline {
  text-decoration: underline;
}

.accessibility-button {
  padding: 8px 25px;
  font-family: 'AirbnbBook';
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  background: #7c7b7bee;
  width: 170px;
}

.accessibility-button-active {
  background: #54b846;
}

.accessibility-button:hover {
  background: #3f8f3f;
}

.accessibility-button-container {
  display: flex;
  justify-content: center;
  padding-top: 44px;
  gap: 12px;
  border-bottom: 1px solid #121f4f;
  padding-bottom: 24px;
  position: fixed;
  left: 0;
  right: 0;
  background: #fff;
}

.accessibility-content-container-header-text {
  text-align: center;
  padding: 20px 0 12px;
  width: 100%;
}

.color-primary {
  color: #121f4f;
}

.accessibility-header-image {
  height: 230px;
  position: absolute;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.accessibility-content-section {
  flex: 1;
  width: 100%;
  display: flex;
  height: 190px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .accessibility-container {
    padding: 80px 27px 0 27px;
  }
  .accessibility-content {
    padding-top: 150px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .accessibility-content-section {
    width: 100%;
    height: unset;
  }

  .accessibility-content-container {
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .accessibility-container,
  .accessibility-button-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .accessibility-content-section p {
    width: 100%;
  }
}
