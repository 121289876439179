.landing-page-container {
    height: 100vh;
}

.background-container {
    height: 100vh;
    display: flex;
    flex: 1;
    background-image: url('../images/home/home-banner-2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: .8s all ease;
    position: relative;
}

.home-text-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 27px;
    background: #00000078;
}

.landing-text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 66%;
    transition: .6s ease-in-out;
    opacity: 1;
    color: #fff;
}

.landing-text h2 {
    /* font-size: 45px; */
    padding-bottom: 18px;
    margin: 0;
    font-weight: 700;
    text-align: right;
    transition: 300ms all ease;
    opacity: 1;
    will-change: opacity;
}

.show {
    opacity: 1 !important;
}

.landing-text p {
    /* font-size: 20px; */
    text-align: right;
    transition: .4s ease-in-out;
    opacity: 1;
    padding-bottom: 12px;
    font-family: 'AirbnbBook';
}

.landing-text-hide {
    opacity: 0;
}

.landing-text-animate-enter {
    opacity: 0.01;
}

.landing-text-animate-enter.landing-text-animate-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.landing-text-animate-leave {
    opacity: 1;
}

.landing-text-animate-leave.landing-text-animate-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
} 

.home-landing-btn {
    padding-top: 20px;
}

.home-landing-btn a {
    padding: 8px 25px;
    background: #54b846;
    font-family: 'AirbnbBook';
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    border-radius: 5px;
    /* font-size: 18px; */
    font-weight: 600;
}

.home-promise-container {
    height: 150px;
    background: #54b846;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #fff;
}

.home-promise-container p {
    /* font-size: 23px; */
    text-transform: uppercase;
    font-family: 'Airbnbbook';
}

.home-promise-container h2 {
    font-weight: 500;
    /* font-size: 35px; */
}

.home-promise-container {
    padding: 10px 27px;
}

.home-body-text {
    padding: 27px 27px;
}

.home-body-top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    text-align: center;
}

.home-body-left-container {
    flex: 1;
}

.home-body-left-container h2, .home-body-right-container h2{
    /* font-size: 33px; */
    padding-bottom: 15px;
}

.home-body-left-container p, .home-body-right-container p{
    /* font-size: 25px; */
    font-family: 'AirbnbBook';
}

.home-body-right-container {
    flex: 1;
    text-align: center;
}

.icons-container {
    padding-left: 0;
    display: flex;
    padding-top: 35px;
    justify-content: center;
    align-items: center;
}

.home-body-thin {
    font-family: 'AirbnbBook';
    font-weight: 100;
}

.home-body-bottom-container {
    display: flex;
    padding-top: 56px;
    flex-direction: column;
}

.home-cl-icons {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.home-cl-container {
    text-align: center;
}

.home-cl-container p{
    padding-top: 5px;
}

.home-cl-icon {
    height: 65px;
}

.home-icons {
    display: grid;
    grid-template-columns: repeat(2,150px);
    grid-gap: 10px;
}

.home-icons-padding {
    padding-bottom: 27px;
}

.home-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 10px;
}

.home-icon-container p{
    padding-top: 15px;
}

.home-icon-container img{
    height: 60px;
}

.home-img-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 27px;
    height: 174px;
}

.image-1 {
    background-image: url('../images/home/home-full1.png');
    margin-bottom: 27px;
}

.image-2 {
    background-image: url('../images/home/home-full2.png');
}

.home-contact-container {
    height: 150px;
    background: #54b846;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 10px 27px;
}

.home-contact-content-container {
    display: flex;
    justify-content: space-between;
}

.home-contact-text {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home-contact-text-container h2{
    font-size: 22px;
}

.home-contact-text-container p{
    /* font-size: 22px; */
    font-family: 'AirbnbBook';
}

.home-contact-btn {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.home-contact-btn a{
    padding: 8px 10px;
    background: #fff;
    font-family: 'AirbnbBook';
    text-transform: uppercase;
    text-decoration: none;
    color: #54b846;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
}

.home-certifications-container {
    padding: 27px 27px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-certifications {
    display: grid;
    grid-template-columns: repeat(4,auto);
    grid-gap: 10px;
}

.home-certification-icon-container {
    display: flex;
    align-items: center;
}

.home-certification-icon {
    height: 45px;
}

/* .second-container {
    width: 89%;
} */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {


    .home-icons {
        grid-template-columns: repeat(3,auto);
    }

    .mini-cl-icons {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .home-certifications {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .home-text-container {
        background: none;
    }

    .landing-text {
        color: #000;
        max-width: 40%;
    }

    .home-contact-content-container {
        width: 89%;
    }

    .second-container {
        width: 89%;
    }

    .home-contact-btn {
        justify-content: center;
    }

    .home-img-container {
        background-position: top;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

    .home-certification-icon {
        height: 68px;
    }

    .home-contact-content-container {
        width: 89%;
    }
    
    .home-body-top-container, .home-body-bottom-container {
        flex-direction: row;
        justify-content: unset;
        align-self: unset;
        text-align: unset;
    }

    .home-body-right-container {
        padding: 0 0 0 29px;
        text-align: unset;
    }

    .home-body-left-container {
        padding: 0 60px 0 0;
    }

    .landing-text h2 {
        font-size: 45px;
    }

    .landing-text p {
        font-size: 20px;
    }

    .home-landing-btn a {
        font-size: 18px;
    }

    .home-promise-container p {
        font-size: 23px;
    }
    
    .home-promise-container h2 {
        font-size: 35px;
    }

    .home-body-left-container h2, .home-body-right-container h2{
        font-size: 33px;
    }
    
    .home-body-left-container p, .home-body-right-container p{
        font-size: 25px;
        max-width: 95%;
    }

    .home-contact-text-container h2{
        font-size: 35px;
    }
    
    .home-contact-text-container p{
        font-size: 22px;
    }

    .home-contact-btn a{
        padding: 8px 67px;
    }

    .home-icon-container p{
        font-size: 15px;
    }

    .home-cl-container p{
        font-size: 15px;
    }

    .home-certifications-container {
        padding: 56px 27px;
    }

    .home-contact-btn a{
        padding: 8px 29px;
        font-size: 18px;
    }

    .home-body-text {
        padding: 56px 27px;
    }

    .home-img-container {
        height: 250px;
        margin-top: 9px;
        margin-right: 25px;
    }

    .image-1 {
        background-image: url('../images/home/home-full1.png');
        margin-bottom: 0;
    }

    .home-contact-text {
        flex: 1;
    }

    .home-icon-container {
        justify-content: center;
    }

    .home-icons {
        width: 100%;
    }

    .home-icons-padding {
        padding-bottom: 0;
    }

    /* .icons-container {
        justify-content: ;
    } */

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .home-text-container, .home-promise-text, .home-body-text, .home-contact-content-container, .home-certifications-container {
        max-width: 1200px;
        margin: 0 auto;
    }

    .background-container {
        background-position: unset;
    }
}