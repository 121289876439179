.top-container {
  background: #f5f5f5;
  /* display: flex;
  flex-direction: column;
  flex-wrap: wrap; */
  padding: 30px 27px;
}

.top-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.footer-section {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  padding: 10px;
}

.footer-section a, .footer-section p {
  padding: 5px 0;
  text-transform: none;
  text-decoration: none;
  color: #000;
  font-size: 13px;
  font-weight: 100;
}

.footer-section h2, .footer-section .empty-header {
  text-transform: uppercase;
  color: #000;
  font-size: 17px;
}

.bottom-container {
  background: #4a4a4a;
  height: 54px;
  color: #fff;
  font-weight: 100;
  padding: 0 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.media-container {
  display: flex;
  justify-content: space-between;
  width: 100px;
}

.media-container img {
  height: 25px;
  cursor: pointer;
}

.media-container a {
  height: 25px;
}

.certification-icons {
  display: flex;
}

.certification-icon {
  height: 30px;
  margin-right: 5px;
}

.mobile-footer-section {
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 20px;
}

.mobile-footer-section:last-of-type {
  padding: 10px 0 0 0;
}

.mobile-footer-header {
  padding: 10px 0;
  border-bottom: 1px solid #e2dbdb;
  cursor: pointer;
}

.mobile-footer-section a{
  padding: 10px 0;
  text-decoration: none;
  color: #000;
  font-family: 'AirbnbBook';
}

.mobile-footer-section p{
  padding: 4px 0;
  text-decoration: none;
  color: #000;
  font-family: 'AirbnbBook';
}

.bottom-container {
  font-size: 14px;
}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  .top-container {
  }

  .footer-section {
    display: flex;
    flex-direction: column;
    /* flex: 1; */
  }

  .footer-section a, .footer-section p {
    padding: 5px 0;
    text-transform: none;
    text-decoration: none;
    color: #000;
    font-size: 13px;
    font-weight: 100;
  }
  
  .footer-section h2, .footer-section .empty-header {
    padding-bottom: 10px;
    height: 30px;
    text-transform: uppercase;
    color: #000;
    font-size: 17px;
  }
  
  .bottom-container {
    background: #4a4a4a;
    height: 54px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 100;
    padding: 0 27px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .top-container {
    padding: 38px 27px;
  }
  
  .footer-section {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
  }

  .footer-section:first-of-type{
    padding: 0 13px 0 0;
  }

  .footer-section:last-of-type {
    padding: 0 0 0 13px;
  }
  
  .footer-section a, .footer-section p {
    padding: 5px 0;
    text-transform: none;
    text-decoration: none;
    color: #000;
    font-size: 13px;
    font-weight: 100;
    font-family: 'AirbnbBook';
  }
  
  .footer-section h2, .footer-section .empty-header {
    padding-bottom: 10px;
    height: 30px;
    text-transform: uppercase;
    color: #000;
    font-size: 17px;
  }
  
  .bottom-container {
    background: #4a4a4a;
    height: 54px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 100;
    padding: 0 27px;
  }

  .certification-icons {
    display: flex;
    flex-wrap: wrap;
    max-width: 350px;
    gap: 5px
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .footer-section a, .footer-section p {
    padding: 5px 0;
    text-transform: none;
    text-decoration: none;
    color: #000;
    font-size: 15px;
    font-weight: 100;
    font-family: 'AirbnbBook';
  }

  .certification-icon {
    height: 40px;
  }

  .top-content-container, .bottom-content-container {
    max-width: 1200px;
    margin: 0 auto;
  }
}