.error-container {
  display: flex;
  flex-direction: column;
  padding: 95px 27px 0 27px;
  height: 64vh;
  justify-content: center;
  align-items: center;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .error-container {
    max-width: 1200px;
    margin: 0 auto;
  }
}