.filter-container {
  display: flex;
  flex-direction: column;
  padding: 69px 27px 0 27px;
}

.filter-toggle-container {
  background: #fff;
  display: flex;
}

.filter-toggle-fixed {
  flex-direction: column;
  display: flex;
  width: 100%;
  color: #000000;
  height: fit-content;
}

.filter-toggle-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-title {
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 15px;
}

.filter-clear {
  padding-bottom: 10px;
  cursor: pointer;
}

.filter-clear p {
  text-transform: uppercase;
  background: #bf2525;
  border-radius: 3px;
  padding: 2px 3px;
  font-size: 16px;
  color: #fff;
}

.filter-title-container {
  display: flex;
  justify-content: space-between;
}

.filter-dropdown-arrow {
  width: 21px;
  padding: 5px 0;
  cursor: pointer;
}

.filter-checkbox {
  padding: 5px 0;
} 

.filter-checkbox label {
  padding-left: 5px;
}

.filter-checkbox-child {
  padding: 5px 0;
}

.filter-checkbox-child label {
  padding-left: 5px;
  font-weight: 300;
}

.filter-toggle-dropdown-contents {
  display: flex;
  flex-direction: column;
  padding-left: 26px;
  border-top: 1px solid #BDBDBD;
  padding-top: 5px;
}

input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"] + span {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 12px;
    height: 12px;
    margin: -1px 0px 0 0;
    vertical-align: middle;
    background: white left top no-repeat;
    border: 1px solid #BDBDBD;
    border-radius: 2px;
    cursor: pointer;
  }
  input[type="checkbox"]:checked + span {
    background: #54b846 -19px top no-repeat;
  }
  
  input[type="checkbox"] + span {
    margin-right: 4px;
  }

.filter-search-container {
  background: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.filter-search-results {
  min-height: 70vh;
  flex: 1 1;
  border-radius: 3px;
  padding: 10px 0 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-searchbar-container {
  background: #fff;
  display: flex;
  flex-direction: column;
}

.filter-searchbar-title {
  font-weight: 900;
  color: #142261;
  margin: 0;
}

.filter-searchbar {
  max-height: 35px;
  height: 35px;
  border-radius: 6px;
  border: 1px solid black;
  margin: 10px 0;
  padding-left: 11px;
  font-size: 18px;
  font-family: 'AirbnbBook';
}

.filter-searchbar:focus {
  outline: none;
}

.modal-container {
  height: 100%;
  /* width: 100vw; */
  width: 100%;
  position: fixed;
  background: #0000009c;
  z-index: 99999;
}

.modal {
  position: fixed;
  width: 95%;
  max-width: 430px;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px;
}

.modal h2 {
  color: #54b846;
  font-weight: 600;
  margin-bottom: 5px;
}

.modal p {
  font-weight: 300;
  font-family: 'AirbnbBook';
  margin-bottom: 15px;
}

.close-modal {
  width: 20px;
  height: 82px;
  background: url('../images/close-button.png');
  background-repeat: no-repeat;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.filter-search-no-match {
  height: 48vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.filter-no-match-btn a{
    padding: 7px 10px;
    background: #54b846;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
}

.filter-no-match-btn {
    padding: 20px 0;
}

.filter-searchbar-special {
  display: flex;
  flex-direction: column;
}

.filter-searchbar-special-filter {
  padding-right: 10px;
  cursor: pointer;
}

.filter-searchbar-special-container {
  display: flex;
  justify-content: space-between;
}

.filter-searchbar-count {
  /* color: #54b846; */
  font-weight: bold;
}

.filter-searchbar-count span{
  color: #000;
  font-weight: 100;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 629px) {
  .filter-search-results {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(280px, 1fr));
    grid-gap: 15px;
    align-items: unset;
  }

  .filter-searchbar-special {
    flex-direction: row;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .filter-container {
    padding: 150px 27px 0 27px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .filter-container {
    padding-top: 150px;
    display: flex;
    flex-direction: row;
  }

  .filter-searchbar {
    font-size: 18px;
  }

  .filter-toggle-container {
    width: 235px;
    min-width: 235px;
    padding-right: 27px;
    padding-top: 10px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .filter-container {
    max-width: 1200px;
    margin: 0 auto;
  }
}
