.history-container {
  display: flex;
  flex-direction: column;
  padding: 95px 27px 0 27px;
}

.history-header-image {
  background: 
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 0.6)
    ),
    url('../images/historyHeader.png');
  height: 230px;
  position: absolute;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.history-content-container {
  margin: 220px 0 25px;
  /* background: url('../images/Timeline.png'); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 1200px;
  display: flex;
  flex-direction: column;
  color: #000;
}

.hisotry-content-container-title {
  text-align: center;
  padding: 20px 0 45px;
  color: #54b846;
}

.history-content-container h1 {
  line-height: 30px;
}

.history-content-section {
  flex: 1;
  width: 100%;
  display: flex;
  height: 190px;
}

.history-content-section-left {
  flex: 5;
  text-align: right;
  background-repeat: no-repeat;
  background-size: 120px;
  background-position-x: right;
}

.history-content-section-left p{
  width: 80%;
  float: right;
  padding-top: 10px;
  font-family: 'AirbnbBook';
  font-size: 17px;
}

.history-content-section-right p {
  width: 80%;
  padding-top: 10px;
  font-family: 'AirbnbBook';
  font-size: 17px;
}

.history-content-section-middle {
  flex: 1;
  background: url('../images/timeline-mini.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.history-content-section-middle-last {
  background: url('../images/timeline-circle.png');
  flex: 1;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 15px;
}

.history-content-section-right {
  flex: 5;
  background-repeat: no-repeat;
  background-size: 120px;
  background-position-x: left;
}

.history-bottle {
  background-size: 154px;
}

.history-content-section-left-mini {
  flex: 1;
  background: url('../images/timeline-mini.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.history-content-section-left-mini-last {
  background: url('../images/timeline-circle.png');
  flex: 1;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 14px;
}

.history-content-section-right-mini {
  flex: 5;
}

.history-content-section-right-mini p{
  font-size: 15px;
  font-family: 'AirbnbBook';
  padding-top: 7px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .history-container {
    padding: 118px 27px 0 27px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .history-content-section {
    width: 100%;
    height: unset;
  }

  .history-content-container {
    height: 1000px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .history-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .history-content-section p {
    width: 100%;
  }
}