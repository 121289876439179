.product-card-container {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 8px;
    display: flex;
    color: #000;
    cursor: pointer;
    height: 443px;
    max-width: 400px;
    margin-bottom: 10px;
}

.product-card-container:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.product-card-margin {
    margin: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.product-card-img-container {
    height: 300px;
}

.product-card-img-container img{
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.product-card-name {
    font-size: 24px;
    font-weight: 800;
}

.product-card-category1 {
    font-weight: 100;
    font-family: 'AirbnbBook';
}

.product-card-category2 {
    font-weight: 100;
    font-family: 'AirbnbBook';
}

.product-card-text {
    padding: 10px 0;
}

.product-card-bottom-container {
    display: flex;
}

.product-card-categories {
    flex: 2;
}

.product-special-filters {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.product-special-filters img{
    height: 22px;
    margin-right: 3px;
}

@media (min-width: 629px) {
    .product-card-container {
        max-width: unset;
        margin-bottom: unset;
    }
}
